<template>
    <div>
        <div class="default_box">
            <div class="default_header">
                <h1>Пользовательское соглашение</h1>
            </div>
            <div class="default_box_content bg_white p15">
                <div class="text-left">
                    Настоящее Соглашение определяет условия использования Пользователями материалов и сервисов сайта www.okaygorod.com (далее — «Сайт»).<br><br>
                    <b>1.Общие условия</b><br>
                    <b>1.1.</b> Использование материалов и сервисов Сайта регулируется нормами действующего законодательства Российской Федерации.<br>
                    <b>1.2.</b> Настоящее Соглашение является публичной офертой. Получая доступ к материалам Сайта Пользователь считается присоединившимся к настоящему Соглашению.<br>
                    <b>1.3.</b> Администрация Сайта вправе в любое время в одностороннем порядке изменять условия настоящего Соглашения. Такие изменения вступают в силу по истечении 3 (Трех) дней с момента размещения новой версии Соглашения на сайте. При несогласии Пользователя с внесенными изменениями он обязан отказаться от доступа к Сайту, прекратить использование материалов и сервисов Сайта.<br><br>
                    <b>2. Обязательства Пользователя</b><br>
                    <b>2.1.</b> Пользователь соглашается не предпринимать действий, которые могут рассматриваться как нарушающие российское законодательство или нормы международного права, в том числе в сфере интеллектуальной собственности, авторских и/или смежных правах, а также любых действий, которые приводят или могут привести к нарушению нормальной работы Сайта и сервисов Сайта.<br>
                    <b>2.2.</b> Использование материалов Сайта без согласия правообладателей не допускается (статья 1270 Г.К РФ). Для правомерного использования материалов Сайта необходимо заключение лицензионных договоров (получение лицензий) от Правообладателей.<br>
                    <b>2.3.</b> При цитировании материалов Сайта, включая охраняемые авторские произведения, ссылка на Сайт обязательна (подпункт 1 пункта 1 статьи 1274 Г.К РФ).<br>
                    <b>2.4.</b> Комментарии и иные записи Пользователя на Сайте не должны вступать в противоречие с требованиями законодательства Российской Федерации и общепринятых норм морали и нравственности.<br>
                    <b>2.5.</b> Пользователь предупрежден о том, что Администрация Сайта не несет ответственности за посещение и использование им внешних ресурсов, ссылки на которые могут содержаться на сайте.<br>
                    <b>2.6.</b> Пользователь согласен с тем, что Администрация Сайта не несет ответственности и не имеет прямых или косвенных обязательств перед Пользователем в связи с любыми возможными или возникшими потерями или убытками, связанными с любым содержанием Сайта, регистрацией авторских прав и сведениями о такой регистрации, товарами или услугами, доступными на или полученными через внешние сайты или ресурсы либо иные контакты Пользователя, в которые он вступил, используя размещенную на Сайте информацию или ссылки на внешние ресурсы.<br>
                    <b>2.7.</b> Пользователь принимает положение о том, что все материалы и сервисы Сайта или любая их часть могут сопровождаться рекламой. Пользователь согласен с тем, что Администрация Сайта не несет какой-либо ответственности и не имеет каких-либо обязательств в связи с такой рекламой.<br><br>
                    <b>3. Прочие условия</b><br>
                    <b>3.1.</b> Все возможные споры, вытекающие из настоящего Соглашения или связанные с ним, подлежат разрешению в соответствии с действующим законодательством Российской Федерации.<br>
                    <b>3.2.</b> Ничто в Соглашении не может пониматься как установление между Пользователем и Администрации Сайта агентских отношений, отношений товарищества, отношений по совместной деятельности, отношений личного найма, либо каких-то иных отношений, прямо не предусмотренных Соглашением.<br>
                    <b>3.3.</b> Признание судом какого-либо положения Соглашения недействительным или не подлежащим принудительному исполнению не влечет недействительности иных положений Соглашения.<br>
                    <b>3.4.</b> Бездействие со стороны Администрации Сайта в случае нарушения кем-либо из Пользователей положений Соглашения не лишает Администрацию Сайта права предпринять позднее соответствующие действия в защиту своих интересов и защиту авторских прав на охраняемые в соответствии с законодательством материалы Сайта.
                    Пользователь подтверждает, что ознакомлен со всеми пунктами настоящего Соглашения и безусловно принимает их.<br>
                </div>
            </div>
        </div>
        <div class="default_header">
            <h1>Политика конфиденциальности</h1>
        </div>
        <div class="default_box_content bg_white p15">
            <div class="text-left">
                Настоящая Политика конфиденциальности персональной информации (далее — <b>Политика</b>) является официальным документом и определяет порядок обработки и защиты информации о физических лицах, пользующихся услугами Портала “Окей Город” (далее – <b>Пользователи</b>).<br><br>
                Политика конфиденциальности действует в отношении всей информации, которую Портал “Окей Город” может получить о Пользователе во время использования им возможностей портала.<br>
                Использование Портала “Окей Город” означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае несогласия с этими условиями Пользователь должен воздержаться от использования Портала “Окей Город”.<br><br>
                <b>1. Персональная информация Пользователей, которую обрабатывает Портал “Окей Город”</b><br>
                <b>1.1.</b> В рамках настоящей Политики под «персональной информацией Пользователя» понимается персональная информация, которую Пользователь предоставляет о себе самостоятельно при регистрации или в процессе использования Портала “Окей Город”, включая персональные данные Пользователя.<br>
                <b>1.2.</b> Настоящая Политика применима только к информации, обрабатываемой в ходе использования Портала “Окей Город” .<br>
                <b>1.3.</b> Портал “Окей Город” не проверяет достоверность персональной информации, предоставляемой Пользователем, и не имеет возможности оценивать его дееспособность. Однако Портал “Окей Город” исходит из того, что пользователь предоставляет достоверную и достаточную персональную информацию и поддерживает эту информацию в актуальном состоянии.<br><br>
                <b>2. Цели обработки персональной информации Пользователей</b><br>
                <b>2.1.</b> Портал “Окей Город” осуществляет обработку только той персональной информации, которая необходима для предоставления возможностей Портала “Окей Город” , а также исполнения Пользовательского соглашения, за исключением случаев, когда законодательством предусмотрено обязательное хранение персональной информации в течение определенного законом срока.<br>
                <b>2.2.</b> Персональную информацию Пользователя Портал “Окей Город” обрабатывает в следующих целях:<br>
                <b>2.2.1.</b> Идентификация стороны в рамках Пользовательского соглашения;<br>
                <b>2.2.2.</b> Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования Портала “Окей Город”, исполнения Пользовательского соглашения, а также обработка запросов и заявок от Пользователя;<br>
                <b>2.2.3.</b> Улучшение качества работы Портала, удобства его использования, разработка новых возможностей Портала “Окей Город”;<br>
                <b>2.2.4.</b> Проведение статистических и иных исследований на основе обезличенных данных.<br><br>
                <b>3. Состав информации о Пользователях</b><br>
                Персональные данные Пользователей включают в себя:<br>
                <b>3.1.</b> предоставляемые Пользователями минимально необходимые для регистрации на Портале: фамилия, имя, отчество, пол, дата рождения, регион, фотография Пользователя, адрес электронной почты, номер телефона, название образовательного учреждения, класс;<br>
                <b>3.2.</b> дополнительно предоставляемые Пользователями по запросу Администрации Сайта в целях исполнения Администрацией Сайта обязательств перед Пользователями, вытекающих из договора на оказание Услуг Сайта. Администрация Сайта вправе, в частности, запросить у Пользователя копию документа, удостоверяющего личность, либо иного документа, содержащего имя, фамилию, фотографию Пользователя, а также иную дополнительную информацию, которая, по усмотрению Администрации Сайта, будет являться необходимой и достаточной для идентификации такого Пользователя и позволит исключить злоупотребления и нарушения прав третьих лиц.<br><br>
                <b>4. Условия обработки персональной информации Пользователей и её передачи третьим лицам.</b><br>
                <b>4.1.</b> В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу лиц.
                Администрация Портала осуществляет обработку персональных данных Пользователя в целях исполнения договора между Администрацией Портала и Пользователем на оказание услуг (Пользовательское соглашение). В силу статьи 6 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных» отдельное согласие пользователя на обработку его персональных данных не требуется. В силу п.п. 2 п. 2 статьи 22 указанного закона Администрация Портала вправе осуществлять обработку персональных данных без уведомления уполномоченного органа по защите прав субъектов персональных данных.
                Персональные данные Пользователей не передаются каким-либо третьим лицам, за исключением случаев, прямо предусмотренных настоящей Политикой конфиденциальности.
                При указании Пользователя или при наличии согласия Пользователя возможна передача персональных данных пользователя третьим лицам-контрагентам Администрации Портала с условием принятия такими контрагентами обязательств по обеспечению конфиденциальности полученной информации.<br>
                <b>4.2.</b> Передача предусмотрена российским или иным применимым законодательством в рамках установленной законодательством процедуры;<br>
                <b>4.2.1.</b> В целях обеспечения возможности защиты прав и законных интересов Портала “Окей Город” в случаях, когда Пользователь нарушает Пользовательское соглашение и настоящую Политику Портала “Окей Город” .<br>
                <b>4.2.2</b> В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг по поручению Портала “Окей Город” .<br>
                <b>4.2.3</b> При обработке персональных данных Пользователей Портал “Окей Город” руководствуется Федеральным законом РФ «О персональных данных».<br><br>
                <b>5. Изменение и удаление персональной информации. Обязательное хранение данных</b><br>
                <b>5.1.</b> Пользователь может в любой момент изменить (обновить, дополнить, удалить) предоставленную им персональную информацию или её часть, воспользовавшись функцией редактирования персональных данных в персональном разделе.
                <b>5.2.</b> Пользователь не может самостоятельно удалить свой аккаунт. Чтобы удалить свой аккаунт, пользователь должен отправить запрос на почтовый адрес Администрации Портала “Окей Город” - ooogoroda@gmail.com.<br>
                <b>5.3.</b> Права, предусмотренные пп. 4.1. и 4.2. настоящей Политики могут быть ограничены в соответствии с требованиями законодательства. В частности, такие ограничения могут предусматривать обязанность Портала “Окей Город” сохранить измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию в соответствии с законодательно установленной процедурой государственному органу.<br><br>
                <b>6. Меры, применяемые для защиты персональной информации Пользователя</b><br>
                Портал “Окей Город” принимает необходимые и достаточные организационные и технические меры для защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней третьих лиц.<br><br>
                <b>7. Изменение Политики конфиденциальности. Применимое законодательство</b><br>
                <b>7.1.</b> Портал “Окей Город” имеет право вносить изменения в настоящую Политику конфиденциальности. При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией.<br>
                <b>7.2.</b> К настоящей Политике и отношениям между Пользователем и Порталом “Окей Город” , возникающим в связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Regulation",
    components: {},
    metaInfo() {
        return {
            title: `Правила портала `,
            meta: [
                {  name: 'description', itemprop: 'description', content: 'Правила на портале Окей Город' },
                {property: 'og:url', content: this.$config.url + this.$route.fullPath},
                {property: 'og:title', content: `Правила портала Окей Город ${this.$myCity.name}`},
                {property: 'og:description', content: 'Правила на портале Окей Город'},
                {property: 'og:image', content:'https://okaygorod.com/images/logoHD.png'},
                {property: 'og:type', content: 'website' }
            ]
        }
    },
    mounted() {
        this.$scrollToTop();
    }

}
</script>
<style scoped>
</style>